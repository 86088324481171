import api from './api';


class ChoiceService {
    async getChoices() {
        var choice_list = []

        const response = await api.get("/choices");

        choice_list = response.data
      
        return choice_list;
    }
    async getChoiceUpdateStatus() {
        var status = {}

        const response = await api.get("/choices/status");

        status = response.data

        return status;
    }
    async getChoiceUpdateLock() {
        var lock = {}

        const response = await api.get("/choices/lock");

        lock = response.data

        return lock;
    }        
    async getChoicesByLensePillar(lense, pillar) {
        var choice_list = []

        const response = await api.get("/choices?lense="+lense+"&pillar="+pillar);
      
        choice_list = response.data       

        return choice_list;
    }
    async getChoice(id) {
        var choice = {}

        const response = await api.get("/choices/"+id);
      
        choice = response.data     

        return choice;
    }    
    async triggerChoiceUpdater() {

        const response = await api.post("/choices/update", {} );

        return response.data ;
    }  
    
  }
  
  export default new ChoiceService();