<template>
  <CRow>
    <CToaster :autohide="2000">
      <template>           
        <div v-if="showSuccessToast">
          <CToast          
          :show.sync="showSuccessToast"
          color="success"
          >
            {{ successToastMessage }}
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showErrorToast">
          <CToast          
          :show.sync="showErrorToast"
          color="danger"
          >
            {{ errorToastMessage }}
          </CToast>
        </div>   
      </template>
    </CToaster>    
    <CCol col="12" xxl="8">
      <CCard>
        <CCardHeader>
          <CButton class="float-left"  variant="ghost" color="dark" disabled>Edit Remediation Item</CButton>
          <CButton class="ml-5 float-right"   @click="openHelp(true)" color="dark" :disabled="true"><span class="material-icons md-18">question_mark</span></CButton>
        </CCardHeader>
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
          <span v-show="!loading">
            <CCardBody class="p-4">
              <CRow>    
                <CCol col="6">          
                <template>              
                  <div :class="{ 'invalid': $v.selectedLens.$invalid }">
                    <label class="typo__label">Lens</label>
                    <multiselect 
                      v-model="$v.selectedLens.$model"                  
                      :options="lenses" 
                      track-by="lens_alias"
                      label="lens_name" 
                      :searchable="false" 
                      :close-on-select="true"   
                      placeholder="Select the Lens of the Remediation Item."
                      :multiple="false"
                      :hideSelected="false"                  
                      :allowEmpty="false"
                      :loading="loadingLenses"
                      :custom-label="lensSelectCustomLabel"
                      @input="reloadChoices($event)"

                    />                          
                    <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> Select the Lens of the Remediation Item.</div></label>                  
                  </div>
                </template>  
                </CCol>
                <CCol col="6">          
                    <template>              
                        <div :class="{ 'invalid': $v.selectedPillar.$invalid }">
                        <label class="typo__label">Pillar</label>
                        <multiselect 
                          v-model="$v.selectedPillar.$model"                  
                          :options="pillars" 
                          track-by="pillar_id"
                          label="pillar_name"   
                          :searchable="false" 
                          :close-on-select="true"   
                          placeholder="Select Pillar for the Remdiation Item."
                          :multiple="false"
                          :hideSelected="false"                  
                          :allowEmpty="false"
                          :loading="loadingPillars"
                          @input="reloadChoices($event)"
                        />                          
                        <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%">Select the Pillar of the Remediation Item.</div></label>                  
                      </div>
                    </template>                                 
                  </CCol>
              </CRow>                               
                <CRow>   
                  <CCol col="8">          
                  <CInput
                    label="Title"
                    placeholder="Enter a Item title"
                    v-model="$v.item.item_title.$model"                  
                    description="Add meaningful title to the Remediation Item."
                    :is-valid="!$v.item.item_title.$invalid"
                  >
                  </CInput>
                  </CCol>
                  <CCol col="2">          

                  <CSelect
                    label="Effort"
                    v-model="item.item_effort"
                    :value.sync="item.item_effort"
                    :options="effortList"
                    description="Select the effort in hours for the Remediation Item."
                  >
                  </CSelect>     
                  </CCol>
                </CRow>
                <CRow>
                  <template>              
                <div :class="{ 'invalid': $v.item.item_desc.$invalid }">
                  <label class="typo__label">Description</label>
                    <quill-editor
                      label="Description"
                      ref="myQuillEditor"
                      v-model="$v.item.item_desc.$model"                
                      :options="editorOption"
                    />
                    <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> Explain what will be done to remediate the selected choices/findings. The description will show up in the customer-facing report.</div></label>                  
                  </div>
                </template> 

                </CRow>
                <template>              
                  <div>
                  <label class="typo__label">Choices</label>
                  <multiselect
                    :value="selectedChoiceList"
                    :options="choices"
                    track-by="id"
                    :searchable="true"
                    :close-on-select="false"
                    :multiple="true"
                    label="name" 
                    :hideSelected="true"
                    @select="selectChoice"
                    @remove="removeChoice"
                    :loading="choicesLoading"
                    :custom-label="selectChoicesCustomLabel"                    
                    placeholder="Pick choices/findings the Remediation Item will address."
                  >
                   </multiselect>
                   <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> Pick choices/findings the Remediation Item will address.</div></label>                  
                  </div>
                </template>                      
              </CCardBody> 
            </span>         
        </CCardBody>
        <CCardFooter>
          <CButton  class="float-left col-sm-2" color="light" @click="goBack">Back</CButton>
          <CButton v-on:click="saveItem();" color="success" class="float-right col-sm-4" :disabled="!$v.$anyDirty || $v.$invalid">Save Item</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>



<script>
import ASelect from '@/components/forms/ASelect.vue';
import Multiselect from 'vue-multiselect';
import LensService from '@/services/lense.services';
import ItemService from '@/services/item.services';
import ChoiceService from '@/services/choice.services';
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators';

export default {
  components: {
    ASelect,
    Multiselect
  },  
  data() {
    return {
      item: {},
      lenses: [],
      pillars: [],      
      choices: [],
      selectedChoiceList: [],
      selectedLenseId: "",
      selectedLens: "",
      selectedPillar: "null",
      effortList: [ 0.0, 0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75, 3.0, 3.25, 3.5, 3.75, 4.0  ],
      editorOption: {},
      loading: true,
      choicesLoading: true,
      loadingPillars: true,
      loadingLenses: true,
      showSuccessToast: false,
      showErrorToast: false,
      successToastMessage: "",
      errorToastMessage: "",
    };
  },
  setup: () => ({ v$: useVuelidate() }),    
  validations: {
    selectedLens: {
      required,
    },
    selectedPillar: {
      required,
    },
    item: {
      item_lense: {
        required,
      },
      item_pillar: {
        required,
      },
      item_title: {
        required,
        $autoDirty: true,
        minLength: minLength(4),
        maxLength: maxLength(100),
        validator: helpers.regex('alpha', /^[a-zA-Z0-9-_&!#=()\s]+$/)
      },
      item_desc: {
        $autoDirty: true,
        minLength: minLength(0),
        maxLength: maxLength(1000),
      },
    }
  },
  methods: {
    selectChoicesCustomLabel(choice){
      let questionId = choice.question_title.split(" ")[0]
      return `${choice.name} [${questionId}]`
    },    
    lensSelectCustomLabel(lens){
      return `${lens.lens_name} [${lens.owner}]`
    },       
    goBack() {
      this.$router.push({path: '/remediation-items'})
    },    
    reloadChoices() {
      this.getChoicesByLensePillar()
    },
    selectChoice(selectedChoice) {
      var tmp = {}
      tmp['id'] = selectedChoice.id
      tmp['name'] = selectedChoice.name
      tmp['question_id'] = selectedChoice.question_id
      tmp['question_title'] = selectedChoice.question_title
      
      this.selectedChoiceList.push(tmp)
    },
    removeChoice(removedOption, id) {
      const index = this.selectedChoiceList.indexOf(removedOption);
      if (index > -1) {
         this.selectedChoiceList.splice(index, 1);
      }
    },
    // onEditorBlur(quill) {
    //   console.log('editor blur!', quill)
    // },
    // onEditorFocus(quill) {
    //   console.log('editor focus!', quill)
    // },
    // onEditorReady(quill) {
    //   console.log('editor ready!', quill)
    // },
    // onEditorChange({ quill, html, text }) {
    //   console.log('editor change!', quill, html, text)
    //   this.items.item_desc = html
    // },
    async processChoicesData() {
    
      for (var key in this.item.item_hri){
        ChoiceService.getChoice(this.item.item_hri[key]).then(
          response => { 
            var choice = response;
            var tmp = {}
            tmp["id"] = choice.choice_id
            tmp["name"] = choice.choice_name
            tmp["question_id"] = choice.choice_question_id
            tmp["question_title"] = choice.choice_question
        
            this.selectedChoiceList.push(tmp)
          },
          error => {   
            console.log(error);
            this.errorToastMessage = "Error loading Choices."
            this.showErrorToast = true;
          }           
        )
      }
    },    
    async getChoicesByLensePillar() {
      ChoiceService.getChoicesByLensePillar(this.item.item_lense, this.item.item_pillar).then(
        response => { 
          // clear choice list
          this.choicesLoading = true;
          this.choices = []
          
          for (var key in response){
            var tmp = {}
            tmp["id"] = response[key].choice_id
            tmp["name"] = response[key].choice_name
            tmp["question_id"] = response[key].choice_question_id
            tmp["question_title"] = response[key].choice_question
        
            this.choices.push(tmp)
          }
          this.choicesLoading = false;
        },
        error => {   
          console.log(error);
          this.errorToastMessage = "Error loading Choices."
          this.showErrorToast = true;
        }           
      );
    },
    async saveItem() {
      var tmp_choices = []
      for (var key in this.selectedChoiceList) {
        tmp_choices.push(this.selectedChoiceList[key].id)
      }

      ItemService.saveItem(this.$route.params.id, this.item.item_desc, this.item.item_title, this.item.item_lense, tmp_choices, this.item.item_pillar, this.item.item_effort).then(
        response => { 
          this.successToastMessage = "Remediation Item saved!";
          this.showSuccessToast = true;
          this.$v.$reset();
        },
        error => {   
          console.log(error);
          this.errorToastMessage = "Error saving Remediation Item!"
          this.showErrorToast = true;
        }
      )
    },         
  },
  created() { },
  async mounted() { 
    await LensService.getLenses().then(
      response => { 
        this.lenses = response.lens_summaries;
        this.loadingLenses = false;

      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Lenses."
        this.showErrorToast = true;
      }      
    )
    await LensService.getPillars().then(
      response => { 
        this.pillars = response.pillars;
        this.loadingPillars = false;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Pillars."
        this.showErrorToast = true;
      }      
    )
    await ItemService.getItem(this.$route.params.id).then(
      response => { 
        this.item = response;
        let lens = this.lenses.find(o => o.lens_alias === this.item.item_lense);
        this.selectedLens = lens;
        let pillar = this.pillars.find(o => o.pillar_id === this.item.item_pillar);
        this.selectedPillar = pillar;        
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Remediation Item."
        this.showErrorToast = true;
      }  
    )    
    await this.processChoicesData();
    await this.getChoicesByLensePillar();

    this.loading = false;

  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
